
import Vue from "vue";
import _ from "lodash";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "NewsFeedPanel",
  props: {
    profile: Object
  },
  data() {
    return {
      filter: {
        my_followers: false,
        my_followings: true,
        my_mentors: true,
        only_post: true,
        only_mentorcast: true
      } as any,
      newsFrom: {
        followers: {
          icon: "users",
          label: (this as any).$t("setting.newsFeedNewsFromSourceMyFollowers"),
          model: "my_followers"
        },
        following: {
          icon: "following-people",
          label: (this as any).$t("setting.newsFeedNewsFromSourceIAmFollowing"),
          model: "my_followings"
        }
      } as any,
      newsFromMentee: {
        mentors: {
          icon: "add-user",
          label: (this as any).$t("setting.newsFeedNewsFromSourceMyMentors"),
          model: "my_mentors"
        }
      },
      filterFor: {
        posts: {
          icon: "posts",
          label: (this as any).$t("setting.newsFeedNewsFromSourcePosts"),
          model: "only_post"
        },
        mentorcasts: {}
        /*mentorcasts: {
          icon: "mentorice-scene",
          label: "Mentorcasts",
          model: "only_mentorcast"
        }*/
      }
    };
  },
  computed: {
    ...mapGetters({
      moduleSettings: "helper/getModuleSettings"
    }),
    mentoringSessionStatus(): any {
      return this.moduleSettings["mentoring_session"] == 1;
    },
    mentorcastStatus(): any {
      return this.moduleSettings["mentorcast"] == 1;
    },
    mentoringProgramStatus(): any {
      return this.moduleSettings["mentoring_program"] == 1;
    },
    mentorLibraryStatus(): any {
      return this.moduleSettings["mentor_library"] == 1;
    },
    feedbackEngineStatus(): any {
      return this.moduleSettings["feedback_engine"] == 1;
    },
    mentorCommunityStatus(): any {
      return this.moduleSettings["mentor_community"] == 1;
    },
    sessionCertificateStatus(): any {
      return this.moduleSettings["session_certificate"] == 1;
    },
    isDisabledNews() {
      return (key: string) => {
        const data = [
          this.filter.my_followers,
          this.filter.my_followings,
          this.filter.my_mentors
        ];
        if (data.filter(i => i).length == 1) {
          return this.filter[key];
        }
        return false;
      };
    },
    isDisabledFilter() {
      return (key: string) => {
        const data = [this.filter.only_post, this.filter.only_mentorcast];
        if (data.filter(i => i).length == 1) {
          return this.filter[key];
        }
        return false;
      };
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function() {
        //@ts-ignore
        this.$emit("filter", this.filter);
      }, 100),
      deep: true
    }
  },
  mounted(): void {
    this.$store.dispatch("helper/GET_MODULE_SETTINGS");

    if (this.mentorcastStatus) {
      this.filterFor.mentorcasts = {
        icon: "mentorice-scene",
        label: "Mentorcasts",
        model: "only_mentorcast"
      };
    }
  },
  methods: {
    profileRoute() {
      const path = "/profile";
      if (this.$route.path !== path) this.$router.push(path);
    }
  }
});
